import { ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

import { authInterceptor, provideTranslate } from '@rp/core';
import { MANAGERS_PROVIDER_TOKEN, HttpManagersProvider } from '@rp/shared/providers';
import { ManagersService } from '@rp/shared/services';

import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideAnimations(),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideTranslate(),
    { provide: MANAGERS_PROVIDER_TOKEN, useClass: HttpManagersProvider },
    ManagersService,
  ],
};
