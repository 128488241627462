import { Route } from '@angular/router';

import { ComingSoonComponent, NotFoundComponent } from '@rp/core';

import { ShellComponent } from './shell/shell.component';

export const appRoutes: Route[] = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'rbac',
      },
      {
        path: 'finance',
        component: ComingSoonComponent,
      },
      {
        path: 'statistic',
        component: ComingSoonComponent,
      },
      {
        path: 'partners',
        loadComponent: () => import('@rp/pages/partners').then(m => m.PartnersComponent),
        data: {
          title: 'headerTitle.partners',
        },
      },
      {
        path: 'offers-wall',
        component: ComingSoonComponent,
      },
      {
        path: 'rbac',
        loadComponent: () => import('@rp/pages/rbac').then(m => m.RoleBasedAccessControlComponent),
        data: {
          title: 'headerTitle.rbac',
        },
      },
      {
        path: 'faq',
        component: ComingSoonComponent,
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'storybook',
    loadComponent: () => import('@rp/storybook').then(m => m.StorybookComponent),
  },
];
